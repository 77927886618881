<template>
  <v-dialog
    v-model="ticketing.showProfilingHierarchyAppUser"
    width="90%"
  >
    <v-card>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>

      <div v-if="ticketing.storeLinkedAppUserToProfilingNode.length > 0">
        <v-data-table
          id="app-user-table"
          :headers="ticketing.linkedAppUserHeaders"
          :items="ticketing.storeLinkedAppUserToProfilingNode"
          :search="search"
          class="elevation-1"
          item-key="app_user_id"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="white"
            >
              <v-toolbar-title>SELECTED {{ profilingItem.node_name }} APP USERS</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-spacer />
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <delete
              :id="item.name"
              @deleted="deleteItem(item)"
            />
          </template>
          <template v-slot:no-data>
            <span color="primary"> No app users yet. Add some! </span>
          </template>
        </v-data-table>
      </div>
      <div>
        <v-data-table
          id="app-user-table"
          v-model="selected"
          :headers="ticketing.appUserHeaders"
          :items="profilingAppUsers"
          :search="search"
          show-select
          class="elevation-1"
          item-key="app_user_id"
          @click:row="(item, row) => row.select(!row.isSelected)"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              color="white"
            >
              <v-toolbar-title>ADD {{ profilingItem.node_name }} APP USERS</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
              <v-spacer />
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            <span color="primary"> No app users yet. Add some! </span>
          </template>
          <template v-slot:[`item.is_already_linked`]="{ item }">
            <v-icon>
              {{ item.is_already_linked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
            </v-icon>
          </template>
        </v-data-table>
      </div>

      <v-card-actions>
        <v-btn
          dark
          color="warning"
          @click="ticketing.showProfilingHierarchyAppUser = false"
        >
          Close
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          color="color_green"
          @click="confirmProfilingAppUser()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  props: {
    profilingItem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      search: '',
      selected: []
    };
  },
  computed: {
    ...mapState(['customer', 'ticketing']),
    profilingAppUsers() {
      return this.ticketing.profilingAppUser.filter(user => !this.ticketing.storeLinkedAppUserToProfilingNode.some(u => u.app_user_id === user.app_user_id));
    }
  },
  async mounted() {
    // this.ticketing.storeLinkedAppUserToProfilingNode.forEach(item => {
    //   this.app_user.aUsers.findIndex(e => e.app_user_id == item.app_user_id) != -1 ? this.app_user.aUsers.splice(this.app_user.aUsers.findIndex(e => e.app_user_id == item.app_user_id), 1) : log.error("already deleted");
    // });
    this.overlay = true;
    await this.listProfilingAppUserAction(this.customer.crm_id);
    this.overlay = false;
  },
  methods: {
    ...mapActions(['linkAppUserToProfilingNodeAction', 'unlinkAppUserToProfilingNodeAction', 'listProfilingAppUserAction']),
    async confirmProfilingAppUser() {
      if (this.selected.length > 0) {
        const data = this.selected.map(item => {
          return {
            app_user_id: item.app_user_id,
            service_id: this.profilingItem.service_id,
            node_id: this.profilingItem.node_id,
            is_already_linked: item.is_already_linked
          };
        });
        this.overlay = true;
        await this.linkAppUserToProfilingNodeAction(data);
        this.overlay = false;
        this.ticketing.showProfilingHierarchyAppUser = false;
        this.selected = [];
      }
    },
    async deleteItem(item) {
      const data = { app_user_id: item.app_user_id,
        service_id: this.profilingItem.service_id,
        node_id: this.profilingItem.node_id
      };
      this.overlay = true;
      await this.unlinkAppUserToProfilingNodeAction(data);
      this.overlay = false;
      this.ticketing.showProfilingHierarchyAppUser = false;
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls /deep/ {
  margin-top: 0 !important;
}
</style>
